import { render, staticRenderFns } from "./PricingCards.vue?vue&type=template&id=8d4fab96&scoped=true&"
import script from "./PricingCards.vue?vue&type=script&lang=js&"
export * from "./PricingCards.vue?vue&type=script&lang=js&"
import style0 from "./PricingCards.vue?vue&type=style&index=0&id=8d4fab96&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d4fab96",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PricingCard: require('/opt/build/repo/components/elements/PricingCard.vue').default})
